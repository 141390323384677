export const SPLIT_TREATMENTS = {
  CTT_DEPRECATION_VIDEO_VISIT: 'ctt-deprecation-video-visit',
  DISABLE_PHI_MASKING: 'disable-pii-masking',
  ENABLE_OFFSHORE_ACCESS: 'enable_offshore_access_based_on_member_data',
  COACH_WEEKLY_GOAL_EXPIRY: 'coach-weekly-goal-expiry-workflow',
  CONVERSATIONS_IN_COMMS_PANEL: 'conversation-tab-in-comms-panel',
  MODULAR_IAM_PAIN_INSIGHTS: 'modular-iam-pain-insights',
  CONFIGURATOR_2_0_ENHANCEMENT: 'configurator-2-0-enhancement',
  VIDEO_VISIT_RECORDING_PLAYBACK: 'video-visit-recording-playback',
  ROBIN_AI_CONVERSATION: 'robin-ai-carehub'
};