export const CONVERSATION_TYPE = 'messaging';
export const CONVERSATION_ROLES = {
  COACH: 'coach'
};
export const OUTREACH_CHANNEL = {
  SMS: 'sms',
  IAM: 'iam'
};
export const ROBIN_AI_CHANNEL = 'Robin AI';
export const CHANNEL_CREATE_ERROR = 'Sorry, we were not able to create a channel';
export const REFRESH = 'Refresh';