import { ApolloError, useApolloClient, WatchQueryFetchPolicy } from '@apollo/client';
import { useClient } from '@splitsoftware/splitio-react';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CoachWorkflowState, CoachWorkflowTypes, OsWorkflowTypes, PtWorkflowTypes } from '../../../custom-types';
import { SseEvents } from '../constants/events';
import { OVERDUE_CHECK_TIME, PAGE_SIZE } from '../constants/gql-query-constants';
import { ON, SPLIT_TREATMENTS } from '../constants/splits';
import { LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME } from '../constants/strings/coach-dashboard-default';
import { OS_NO_RESPONSE_PREDICTED_TAB_NAME } from '../constants/strings/os-layout';
import { NO_RESPONSE_PREDICTED_TAB_NAME } from '../constants/strings/pt-layout';
import { BULK_WORKFLOW_TRANSITIONED_EVENT_RECEIVED, BULK_WORKFLOW_TRANSITIONED_HANDLE_FAILED, BULK_WORKFLOW_TRANSITIONED_HANDLE_SUCCESS, BULK_WORKFLOW_TRANSITIONED_HANDLE_TIMEDOUT, HLN_COMPLETED, MARK_COMPLETE_MESSAGE, UNREAD_COMPLETED, WORKFLOW_TRANSITIONED_EVENT_RECEIVED } from '../constants/strings/workflows-table';
import { useShellContext } from '../contexts/shell.context';
import { completeAndArchivedStates, completeWorkflowFilters, DashboardType, hiddenNonCompleteStates, UnreadFilterType, WorkflowSseData } from '../custom-types';
import { bulkShowHLNFailedAlert, bulkShowHLNSuccessAlert, bulkShowUnreadFailedAlert, bulkShowUnreadSuccessAlert, bulkWorkflowHLNProgress, bulkWorkflowStateAtom, bulkWorkflowUnreadProgress, currentSelectedWorkflowTypeAtom, dashboardFilterPrevState, healthLogNotesSplitFlag, listToMarkWorkflowComplete, listToMarkWorkflowPending, markCompleteAlertMessage, markCompleteAlertType, ptHealthLogNotesMlSplitFlag, ptUnreadMessagesMlSplitFlag, selectAllPTWorkflowAtom, selectAllWorkflowAtom, selectedCoachDashboardFilters, selectedDashboardType, selectedOsDashboardFilters, selectedPtDashboardFilters, selectedUnreadType, shouldRefetchWorkflows, showMarkCompleteAlert, sortWorkflowsAtom, unreadMessagesMlSplitFlag, workflowIdsFailedTransition, workflowIdsSuccessfullyTransitioned } from '../jotai/atoms';
import { useGetWorkflowsForCoachLazyQuery, useGetWorkflowsForOsLazyQuery, useGetWorkflowsForPtLazyQuery, WorkflowListFiltersInputDto, WorkflowPayload, WorkflowPriority } from '../types';
import { CoachBulkTransition, CoachDashboardFilters, CommonCoachState, commonCompletedStates } from '../types/coach';
import { OsDashboardFilters } from '../types/os';
import { commonPtCompletedStates, PtDashboardFilters } from '../types/pt';
import { VideoVisitsState } from '../types/video-visit';
import { getEndOfDay, getEndOfTomorrowInBusinessDays, getStartOfDay } from '../utils/get-formatted-date';
import { useFilterStates } from './use-filter-states';
export const useCustomEventListener = (eventName: string, callback: (event: Event) => void): void => {
  useEffect(() => {
    const eventListener = (event: Event): void => {
      callback(event);
    };
    window.addEventListener(eventName, eventListener);
    return (): void => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, callback]);
};
export const useGetDashboardWorkflows = (dashboardType: DashboardType): {
  workflows: WorkflowPayload[] | undefined;
  error: ApolloError | undefined;
  loading: boolean;
} => {
  const apolloClient = useApolloClient();
  const {
    adminProfile,
    useSse,
    mixpanelTrack
  } = useShellContext();
  const [selectedCoachDashboardFilter] = useAtom(selectedCoachDashboardFilters);
  const [selectedPtDashboardFilter] = useAtom(selectedPtDashboardFilters);
  const [selectedOsDashboardFilter] = useAtom(selectedOsDashboardFilters);
  const [getCoachWorkflows, {
    data: coachData,
    error: coachError,
    loading: coachLoading
  }] = useGetWorkflowsForCoachLazyQuery();
  const [getPtWorkflows, {
    data: ptData,
    error: ptError,
    loading: ptLoading
  }] = useGetWorkflowsForPtLazyQuery();
  const [getOsWorkflows, {
    data: osData,
    error: osError,
    loading: osLoading
  }] = useGetWorkflowsForOsLazyQuery();
  const isPtDashboard = dashboardType === DashboardType.PhysicalTherapist;
  const isOsDashboard = dashboardType === DashboardType.OnboardingSpecialist;
  const getByDashboardType = useCallback(<A, B, C>(ptValue: A, osValue: B, coachValue: C): A | B | C => {
    if (isPtDashboard) {
      return ptValue;
    } else if (isOsDashboard) {
      return osValue;
    } else {
      return coachValue;
    }
  }, [isOsDashboard, isPtDashboard]);
  const selectedDashboardFilter = getByDashboardType(selectedPtDashboardFilter, selectedOsDashboardFilter, selectedCoachDashboardFilter);
  const data = getByDashboardType(ptData, osData, coachData);
  const error = getByDashboardType(ptError, osError, coachError);
  const loading = getByDashboardType(ptLoading, osLoading, coachLoading);
  const getWorkflowsLazyQuery = getByDashboardType(getPtWorkflows, getOsWorkflows, getCoachWorkflows);
  const [workflows, setWorkflows] = useState<WorkflowPayload[]>([]);
  const splitClient = useClient();
  const coachFilterStates = useFilterStates(DashboardType.Coach);
  const ptFilterStates = useFilterStates(DashboardType.PhysicalTherapist);
  const osFilterStates = useFilterStates(DashboardType.OnboardingSpecialist);
  const enableWorkflowStateFilteringTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.ENABLE_WORKFLOW_STATE_FILTERING, {
    adminUuid: adminProfile?.uuid || '*'
  }) === ON;
  const useCoverage = dashboardType === DashboardType.Coach || dashboardType === DashboardType.OnboardingSpecialist;
  const completedStates = isPtDashboard ? commonPtCompletedStates : commonCompletedStates;
  const [lastSse, setLastSse] = useState<Record<string, unknown>>({});
  const [sseMismatch, setSseMismatch] = useState(false);
  const [updateHealthLogNotesSplitFlag, setUpdateHealthLogNotesSplitFlag] = useAtom(healthLogNotesSplitFlag);
  const [unreadMessagesMlSplitFlagValue, setUnreadMessagesMlSplitFlagValue] = useAtom(unreadMessagesMlSplitFlag);
  const [ptUnreadMessagesMlSplitFlagValue, setPtUnreadMessagesMlSplitFlagValue] = useAtom(ptUnreadMessagesMlSplitFlag);
  const [ptHealthLogNotesMlSplitFlagValue, setPtHealthLogNotesMlSplitFlagValue] = useAtom(ptHealthLogNotesMlSplitFlag);
  const [sortWorkflows] = useAtom(sortWorkflowsAtom);
  const [, setBulkState] = useAtom(bulkWorkflowStateAtom);
  const [bulkHLNProgress, setBulkHLNProgress] = useAtom(bulkWorkflowHLNProgress);
  const [bulkUnreadProgress, setBulkUnreadProgress] = useAtom(bulkWorkflowUnreadProgress);
  const [prevFilter] = useAtom(dashboardFilterPrevState);
  const [, setBulkHLNSuccess] = useAtom(bulkShowHLNSuccessAlert);
  const [, setBulkUnreadSuccess] = useAtom(bulkShowUnreadSuccessAlert);
  const [, setBulkHLNFailed] = useAtom(bulkShowHLNFailedAlert);
  const [, setBulkUnreadFailed] = useAtom(bulkShowUnreadFailedAlert);
  const [, setAllWorkflowFlag] = useAtom(selectAllWorkflowAtom);
  const [, setAllPtWorkflowFlag] = useAtom(selectAllPTWorkflowAtom);
  const [, setListToMarkComplete] = useAtom(listToMarkWorkflowComplete);
  const [, setListToMarkPending] = useAtom(listToMarkWorkflowPending);
  const [, setAlertType] = useAtom(markCompleteAlertType);
  const [, setMessage] = useAtom(markCompleteAlertMessage);
  const [, setShowMarkCompleteAlert] = useAtom(showMarkCompleteAlert);
  const [, setWorkflowSuccessful] = useAtom(workflowIdsSuccessfullyTransitioned);
  const [, setWorkflowFailed] = useAtom(workflowIdsFailedTransition);
  const [lastBulkSse, setLastBulkSse] = useState<Record<string, unknown>>({});
  const [sseBulkMismatch, setSseBulkMismatch] = useState(false);
  const [ptPooledDashboardAdminId, setPtPooledDashboardAdminId] = useState<string | null>(null);
  const [currentDashboard] = useAtom(selectedDashboardType);
  const [isAssignmentDone, setIsAssignmentDone] = useState(false);
  const [refetchWorkflows] = useAtom(shouldRefetchWorkflows);
  const [currentWorkflowType] = useAtom(currentSelectedWorkflowTypeAtom);
  const selectedUnreadTypeValue = useAtomValue(selectedUnreadType);
  const mixpanelProperties = {
    adminId: adminProfile?.uuid,
    role: adminProfile?.role
  };
  const invokeMixpanelEvent = async (event: string, eventProperties?: object): Promise<void> => {
    mixpanelTrack?.(event, {
      ...mixpanelProperties,
      ...eventProperties
    });
  };
  setUpdateHealthLogNotesSplitFlag(splitClient?.getTreatment(SPLIT_TREATMENTS.HEALTH_LOG_NOTES, {
    adminUuid: adminProfile.uuid
  }) === ON);
  setUnreadMessagesMlSplitFlagValue(splitClient?.getTreatment(SPLIT_TREATMENTS.UNREAD_MESSAGE_ML, {
    adminUuid: adminProfile.uuid
  }) === ON);
  setPtUnreadMessagesMlSplitFlagValue(splitClient?.getTreatment(SPLIT_TREATMENTS.PT_UNREAD_MESSAGES_ML, {
    adminUuid: adminProfile.uuid
  }) === ON);
  setPtHealthLogNotesMlSplitFlagValue(splitClient?.getTreatment(SPLIT_TREATMENTS.PT_UNREAD_MESSAGES_ML, {
    adminUuid: adminProfile.uuid
  }) === ON);
  const sortingEnabledWorkflowFilters = useMemo(() => {
    const fetchWorkflowsSortedMap = new Map<string, boolean>();
    const coachDashboardFilters = Object.values(CoachDashboardFilters);
    const ptDashboardFilters = Object.values(PtDashboardFilters);
    const allDashboardFilters = [...coachDashboardFilters, ...ptDashboardFilters];
    const sortingEnabledWorkflowsFilters = allDashboardFilters.map(filter => {
      switch (filter) {
        case CoachDashboardFilters.UnreadMessages:
          return {
            filter,
            isSortingAllowed: unreadMessagesMlSplitFlagValue
          };
        case PtDashboardFilters.UnreadMessage:
          return {
            filter,
            isSortingAllowed: ptUnreadMessagesMlSplitFlagValue
          };
        case CoachDashboardFilters.MemberHealthLog:
          return {
            filter,
            isSortingAllowed: updateHealthLogNotesSplitFlag
          };
        case PtDashboardFilters.HealthLogNotes:
          return {
            filter,
            isSortingAllowed: ptHealthLogNotesMlSplitFlagValue
          };
        case CoachDashboardFilters.WeeklyEnsoReview:
          return {
            filter,
            isSortingAllowed: true
          };
        default:
          return {
            filter,
            isSortingAllowed: false
          };
      }
    });
    sortingEnabledWorkflowsFilters.forEach(({
      filter: filter_0,
      isSortingAllowed
    }) => {
      fetchWorkflowsSortedMap.set(filter_0, isSortingAllowed);
    });
    return fetchWorkflowsSortedMap;
  }, [unreadMessagesMlSplitFlagValue, ptUnreadMessagesMlSplitFlagValue, updateHealthLogNotesSplitFlag, ptHealthLogNotesMlSplitFlagValue]);
  const fetchWorkflowsSorted = sortingEnabledWorkflowFilters.get(selectedDashboardFilter.filterLabel);
  const lowPriorityNotesSplitFlag = updateHealthLogNotesSplitFlag || unreadMessagesMlSplitFlagValue;
  const sseEvents = [SseEvents.WorkflowCreated, SseEvents.WorkflowTransitioned, SseEvents.TaskUpdated];
  const sseData = useSse(`${process.env.REACT_APP_BFF_URL}/user-workflows/subscribe`, sseEvents, {
    replayLastEvent: true
  });
  const bulkMarkSseData = useSse(`${process.env.REACT_APP_BFF_URL}/user-workflows/subscribe`, [SseEvents.BulkWorkflowTransitionCompleted, SseEvents.BulkWorkflowTransitionFailed], {
    replayLastEvent: true
  });
  const dueTaskEndDate = isPtDashboard ? undefined : getEndOfTomorrowInBusinessDays();

  /**Bulk mark sse events code  Ended*/

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFetchPolicy = (): WatchQueryFetchPolicy => {
    if (!(sseMismatch || sseBulkMismatch) && !refetchWorkflows) {
      return 'cache-first';
    }
    return 'network-only';
  };
  const fetchCoachWorkflows = useCallback(() => {
    if (loading) return;
    const fetchPolicy = getFetchPolicy();
    const sortingVariables = {
      ...(selectedDashboardFilter.filterTabName === NO_RESPONSE_PREDICTED_TAB_NAME ? {
        priority: WorkflowPriority.Low
      } : []),
      order: sortWorkflows.order,
      orderBy: sortWorkflows.property
    };
    const dashboardWorkflowFiltersInput: WorkflowListFiltersInputDto = {
      pageSize: PAGE_SIZE,
      pageNumber: selectedDashboardFilter.filterPageNumber + 1,
      adminUuids: [ptPooledDashboardAdminId || adminProfile.uuid],
      useCoverage
    };
    if (completeWorkflowFilters.includes(selectedDashboardFilter.filterLabel)) {
      getWorkflowsLazyQuery({
        variables: {
          workflowFiltersInput: {
            ...dashboardWorkflowFiltersInput,
            ...getByDashboardType(ptFilterStates[PtDashboardFilters.Completed], osFilterStates[OsDashboardFilters.Completed], coachFilterStates[CoachDashboardFilters.Completed]),
            ...(enableWorkflowStateFilteringTreatment ? {
              includeOnlyCompletedStates: true
            } : {
              completedStates
            }),
            completedAfterDate: getStartOfDay(),
            completedBeforeDate: getEndOfDay(),
            ...sortingVariables
          },
          includeExpirations: true
        },
        pollInterval: OVERDUE_CHECK_TIME,
        errorPolicy: 'all',
        fetchPolicy,
        notifyOnNetworkStatusChange: false,
        onCompleted: () => setIsAssignmentDone(false)
      });
    } else {
      getWorkflowsLazyQuery({
        variables: {
          workflowFiltersInput: {
            ...dashboardWorkflowFiltersInput,
            ...getByDashboardType(ptFilterStates[selectedPtDashboardFilter.filterLabel], osFilterStates[selectedOsDashboardFilter.filterLabel], coachFilterStates[selectedCoachDashboardFilter.filterLabel]),
            ...(enableWorkflowStateFilteringTreatment ? {
              excludeAllCompletedStates: true,
              excludeAllPassiveStates: true
            } : {
              completedStates
            }),
            dueTaskEndDate,
            ...(fetchWorkflowsSorted ? sortingVariables : [])
          },
          includeExpirations: true
        },
        pollInterval: OVERDUE_CHECK_TIME,
        errorPolicy: 'all',
        fetchPolicy,
        notifyOnNetworkStatusChange: false,
        onCompleted: () => setIsAssignmentDone(false)
      });
    }
    setSseMismatch(false);
    setSseBulkMismatch(false);
  }, [loading, getFetchPolicy, selectedDashboardFilter.filterTabName, selectedDashboardFilter.filterPageNumber, selectedDashboardFilter.filterLabel, sortWorkflows.order, sortWorkflows.property, ptPooledDashboardAdminId, adminProfile.uuid, completedStates, useCoverage, getWorkflowsLazyQuery, selectedOsDashboardFilter.filterLabel, getByDashboardType, ptFilterStates, selectedPtDashboardFilter.filterLabel, osFilterStates, coachFilterStates, selectedCoachDashboardFilter.filterLabel, dueTaskEndDate, fetchWorkflowsSorted, enableWorkflowStateFilteringTreatment]);
  const fetchCoachWorkflowsForSortedTabs = useCallback(() => {
    if (loading) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getFilterStateForLazyQuery = (): any => {
      let filterState;
      if (isPtDashboard && [PtDashboardFilters.UnreadMessage].includes(selectedPtDashboardFilter.filterLabel) && selectedUnreadTypeValue) {
        filterState = ptFilterStates[((selectedUnreadTypeValue as unknown) as PtDashboardFilters)];
      } else if (isPtDashboard) {
        filterState = ptFilterStates[selectedPtDashboardFilter.filterLabel];
      } else if (isOsDashboard) {
        filterState = osFilterStates[selectedOsDashboardFilter.filterLabel];
      } else {
        filterState = coachFilterStates[selectedCoachDashboardFilter.filterLabel];
      }
      return filterState;
    };
    const fetchPolicy_0 = getFetchPolicy();
    const dashboardWorkflowFiltersInput_0 = {
      pageSize: PAGE_SIZE,
      pageNumber: selectedDashboardFilter.filterPageNumber + 1,
      adminUuids: [ptPooledDashboardAdminId || adminProfile.uuid],
      useCoverage
    };
    if (isPtDashboard && [PtDashboardFilters.UnreadMessage, PtDashboardFilters.HealthLogNotes].includes(selectedPtDashboardFilter.filterLabel) && selectedPtDashboardFilter.filterTabName === LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME || dashboardType === DashboardType.Coach && [CoachDashboardFilters.UnreadMessages, CoachDashboardFilters.MemberHealthLog].includes(selectedCoachDashboardFilter.filterLabel) && selectedCoachDashboardFilter.filterTabName === LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME || isOsDashboard && selectedOsDashboardFilter.filterLabel == OsDashboardFilters.UnreadMessage && selectedOsDashboardFilter.filterTabName === OS_NO_RESPONSE_PREDICTED_TAB_NAME) {
      getWorkflowsLazyQuery({
        variables: {
          workflowFiltersInput: {
            ...dashboardWorkflowFiltersInput_0,
            ...getFilterStateForLazyQuery(),
            ...(enableWorkflowStateFilteringTreatment ? {
              excludeAllCompletedStates: true,
              excludeAllPassiveStates: true
            } : {
              completedStates
            }),
            dueTaskEndDate,
            priority: WorkflowPriority.Low,
            order: sortWorkflows.order,
            orderBy: sortWorkflows.property
          },
          includeExpirations: true
        },
        pollInterval: OVERDUE_CHECK_TIME,
        errorPolicy: 'all',
        fetchPolicy: fetchPolicy_0,
        notifyOnNetworkStatusChange: false,
        onCompleted: () => setIsAssignmentDone(false)
      });
    } else {
      getWorkflowsLazyQuery({
        variables: {
          workflowFiltersInput: {
            ...dashboardWorkflowFiltersInput_0,
            ...getFilterStateForLazyQuery(),
            ...(enableWorkflowStateFilteringTreatment ? {
              excludeAllCompletedStates: true,
              excludeAllPassiveStates: true
            } : {
              completedStates
            }),
            dueTaskEndDate,
            order: sortWorkflows.order,
            orderBy: sortWorkflows.property
          },
          includeExpirations: true
        },
        pollInterval: OVERDUE_CHECK_TIME,
        errorPolicy: 'all',
        fetchPolicy: fetchPolicy_0,
        notifyOnNetworkStatusChange: false,
        onCompleted: () => setIsAssignmentDone(false)
      });
    }
    setSseMismatch(false);
    setSseBulkMismatch(false);
  }, [loading, getFetchPolicy, selectedDashboardFilter.filterPageNumber, ptPooledDashboardAdminId, adminProfile.uuid, completedStates, useCoverage, isPtDashboard, selectedPtDashboardFilter.filterLabel, selectedPtDashboardFilter.filterTabName, dashboardType, selectedCoachDashboardFilter.filterLabel, selectedCoachDashboardFilter.filterTabName, isOsDashboard, selectedOsDashboardFilter.filterLabel, selectedOsDashboardFilter.filterTabName, selectedUnreadTypeValue, ptFilterStates, osFilterStates, coachFilterStates, getWorkflowsLazyQuery, dueTaskEndDate, sortWorkflows.order, sortWorkflows.property, enableWorkflowStateFilteringTreatment]);
  useEffect(() => {
    if (fetchWorkflowsSorted) {
      fetchCoachWorkflowsForSortedTabs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sseMismatch, sortWorkflows, sseBulkMismatch, ptPooledDashboardAdminId, selectedDashboardFilter]);
  useEffect(() => {
    if (!fetchWorkflowsSorted || ptPooledDashboardAdminId !== null) {
      fetchCoachWorkflows();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sseMismatch, sseBulkMismatch, ptPooledDashboardAdminId, selectedDashboardFilter.filterPageNumber, selectedDashboardFilter.filterLabel]);
  useEffect(() => {
    if (isAssignmentDone) {
      fetchCoachWorkflowsForSortedTabs();
      fetchCoachWorkflows();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAssignmentDone]);
  useEffect(() => {
    if (refetchWorkflows) {
      invokeMixpanelEvent(BULK_WORKFLOW_TRANSITIONED_HANDLE_TIMEDOUT);
      fetchCoachWorkflowsForSortedTabs();
      fetchCoachWorkflows();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchWorkflows]);
  const handleBulkWorkflowEvent = (bulkMarkData: CoachBulkTransition, workflowType: CoachWorkflowTypes | PtWorkflowTypes | OsWorkflowTypes, bulkProgress: boolean, setBulkProgress: (value: boolean) => void, setBulkSuccess: (value: boolean) => void, setBulkFailed: (value: boolean) => void, stateType: string, event_0: string): void => {
    if (bulkMarkData.workflowType === workflowType) {
      if (bulkProgress) {
        setBulkProgress(false);
        if (bulkMarkData.type === SseEvents.BulkWorkflowTransitionCompleted) {
          setBulkSuccess(true);
          setBulkState(prevBulkWorkflowState => ({
            ...prevBulkWorkflowState,
            [stateType]: 'completed'
          }));
          setWorkflowSuccessful(prev => ({
            ...prev,
            [stateType]: bulkMarkData.workflowIdsSuccessfullyTransitioned.length
          }));
          invokeMixpanelEvent(BULK_WORKFLOW_TRANSITIONED_HANDLE_SUCCESS, bulkMarkData);
        } else {
          setBulkState(prevBulkWorkflowState_0 => ({
            ...prevBulkWorkflowState_0,
            [stateType]: 'fail'
          }));
          setBulkFailed(true);
          setWorkflowFailed(prev_0 => ({
            ...prev_0,
            [stateType]: bulkMarkData.workflowIdsFailedTransition.length
          }));
          invokeMixpanelEvent(BULK_WORKFLOW_TRANSITIONED_HANDLE_FAILED, bulkMarkData);
        }
        setAllWorkflowFlag(false);
        setAllPtWorkflowFlag(false);
        setListToMarkComplete([]);
        setListToMarkPending([]);
        window.dispatchEvent(new Event(event_0));
      }
    }
  };
  useEffect(() => {
    if (sseData) {
      const taskSseData = (sseData as {
        type: string;
        workflow: WorkflowPayload;
        stateName: string;
      });
      const workflowSseData = (sseData as WorkflowSseData);
      let workflow: WorkflowSseData;
      if (taskSseData.type === SseEvents.TaskUpdated) {
        const taskWorkflow = taskSseData.workflow;
        workflow = {
          type: taskSseData.type,
          workflowId: taskWorkflow.id,
          serviceName: (taskWorkflow.type as CoachWorkflowTypes),
          stateName: taskWorkflow.state
        };
      } else {
        workflow = workflowSseData;
      }
      const {
        serviceName,
        stateName
      } = workflow;
      const setAndRecordDataRefresh = (): void => {
        setSseMismatch(true);
        setLastSse(sseValues => ({
          ...sseValues,
          [JSON.stringify(selectedDashboardFilter)]: sseData
        }));
      };
      const refetchFromWorkflowEvent = async (): Promise<void> => {
        const filters = getByDashboardType(ptFilterStates[selectedPtDashboardFilter.filterLabel], osFilterStates[selectedOsDashboardFilter.filterLabel], coachFilterStates[selectedCoachDashboardFilter.filterLabel]);
        if (completeWorkflowFilters.includes(selectedDashboardFilter.filterLabel)) {
          if (completeAndArchivedStates.includes((stateName as CommonCoachState))) {
            setAndRecordDataRefresh();
            return;
          }
        }
        if ((filters.workflowTypes as string[]).includes(serviceName) && (!filters.excludeStates?.includes(stateName) || stateName === VideoVisitsState.CompleteVideoVisit || stateName === VideoVisitsState.PerformVideoVisitFollowup)) {
          // We've been notified about a new workflow potentially in the current view. Get it and add it to the list.
          setAndRecordDataRefresh();
        } else if (completeAndArchivedStates.includes((stateName as CommonCoachState)) || hiddenNonCompleteStates.includes((stateName as CommonCoachState))) {
          setAndRecordDataRefresh();
        }
      };

      // We have not already refetched for this filter and event

      if (JSON.stringify(sseData) !== JSON.stringify(lastSse[JSON.stringify(selectedDashboardFilter)])) {
        refetchFromWorkflowEvent();
        if (taskSseData.type === SseEvents.WorkflowTransitioned && taskSseData.stateName === CoachWorkflowState.Complete && selectedDashboardFilter !== prevFilter && JSON.stringify(sseData) !== JSON.stringify(lastSse[JSON.stringify(prevFilter)])) {
          invokeMixpanelEvent(WORKFLOW_TRANSITIONED_EVENT_RECEIVED, taskSseData);
          setAlertType('success');
          setShowMarkCompleteAlert(true);
          setMessage(MARK_COMPLETE_MESSAGE.SUCCESS);
          setAllWorkflowFlag(false);
          setListToMarkComplete([]);
          setListToMarkPending([]);
        }
      }
    }
    const noResponsePredictedType = {
      Unread: 'Unread',
      HLN: 'HLN'
    };
    if (bulkMarkSseData && lowPriorityNotesSplitFlag) {
      //**Bulk workflow Code Started*/
      const bulkMarkData_0 = (bulkMarkSseData as CoachBulkTransition);
      sessionStorage.setItem('unreadProgress', 'false');
      sessionStorage.setItem('hlnProgress', 'false');
      invokeMixpanelEvent(BULK_WORKFLOW_TRANSITIONED_EVENT_RECEIVED, bulkMarkData_0);
      let unreadMessageType;
      if (currentDashboard === DashboardType.Coach) {
        unreadMessageType = CoachWorkflowTypes.UnreadMessages;
      } else if (currentDashboard === DashboardType.OnboardingSpecialist) {
        unreadMessageType = OsWorkflowTypes.UnreadMessages;
      } else if (currentWorkflowType === PtWorkflowTypes.ModularIamUnreadMessages) {
        unreadMessageType = PtWorkflowTypes.ModularIamUnreadMessages;
      } else {
        unreadMessageType = PtWorkflowTypes.UnreadMessages;
      }
      const healthLogType = currentDashboard === DashboardType.Coach ? CoachWorkflowTypes.MemberHealthLog : PtWorkflowTypes.HealthLog;
      handleBulkWorkflowEvent(bulkMarkData_0, unreadMessageType, bulkUnreadProgress, setBulkUnreadProgress, setBulkUnreadSuccess, setBulkUnreadFailed, noResponsePredictedType.Unread, UNREAD_COMPLETED);
      handleBulkWorkflowEvent(bulkMarkData_0, healthLogType, bulkHLNProgress, setBulkHLNProgress, setBulkHLNSuccess, setBulkHLNFailed, noResponsePredictedType.HLN, HLN_COMPLETED);
      const refetchFromBulkWorkflowEvent = async (): Promise<void> => {
        if (selectedDashboardFilter.filterLabel === CoachDashboardFilters.Completed) {
          setAndRecordBulkDataRefresh();
          return;
        }
        const filters_0 = getByDashboardType(ptFilterStates[selectedPtDashboardFilter.filterLabel], osFilterStates[selectedOsDashboardFilter.filterLabel], coachFilterStates[selectedCoachDashboardFilter.filterLabel]);
        if (filters_0.workflowTypes.includes(bulkMarkData_0.workflowType) && !filters_0.excludeStates?.includes(CommonCoachState.CoachComplete)) {
          setAndRecordBulkDataRefresh();
        } else if (completeAndArchivedStates.includes(CommonCoachState.CoachComplete) || hiddenNonCompleteStates.includes(CommonCoachState.CoachComplete)) {
          setAndRecordBulkDataRefresh();
        }
      };
      const setAndRecordBulkDataRefresh = (): void => {
        setSseBulkMismatch(true);
        setLastBulkSse(sseValues_0 => ({
          ...sseValues_0,
          [JSON.stringify(selectedDashboardFilter)]: bulkMarkData_0
        }));
      };
      if (JSON.stringify(bulkMarkSseData) !== JSON.stringify(lastBulkSse[JSON.stringify(selectedDashboardFilter)])) {
        refetchFromBulkWorkflowEvent();
      }
      setAllWorkflowFlag(false);
      setAllPtWorkflowFlag(false);
      setListToMarkComplete([]);
      setListToMarkPending([]);

      //**Bulk workflow Code Ended*/
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sseData, workflows, selectedDashboardFilter, coachFilterStates, ptFilterStates, bulkMarkSseData]);
  useEffect(() => {
    const workflowsFromList = data?.getWorkflowsByFilter?.workflows;
    if (workflowsFromList) {
      setWorkflows((workflowsFromList as WorkflowPayload[]));
    }
  }, [data]);
  useCustomEventListener('onPtPooledDashboardChange', (event_1: Event) => {
    const newAdminId = (event_1 as CustomEvent<string>).detail;
    // Reset apollo cache whenever the PT pooled dashboard is loaded
    apolloClient.resetStore();
    setPtPooledDashboardAdminId(newAdminId);
  });
  useCustomEventListener('onWorkflowsAssigned', () => {
    setIsAssignmentDone(true);
  });
  useCustomEventListener('onLoadPTDashboard', () => {
    // Reset apollo cache whenever the PT pooled dashboard is loaded
    apolloClient.resetStore();
  });
  useEffect(() => {
    if ([PtDashboardFilters.UnreadMessage].includes(selectedPtDashboardFilter.filterLabel)) {
      const filterLabel = getFilterLabel((selectedUnreadTypeValue as string));
      fetchUnreadFilteredWorkflows(filterLabel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnreadTypeValue, selectedPtDashboardFilter.filterLabel]);
  const getFilterLabel = (filterType: string): string => {
    let filterLabel_0;
    if (filterType === UnreadFilterType.AllTypes) {
      filterLabel_0 = PtDashboardFilters.UnreadMessage;
    } else if (filterType === UnreadFilterType.HealthLog) {
      filterLabel_0 = PtDashboardFilters.ModularIamUnreadMessages;
    } else {
      filterLabel_0 = PtDashboardFilters.Messages;
    }
    return filterLabel_0;
  };
  const fetchUnreadFilteredWorkflows = useCallback(filterLabel_1 => {
    const dashboardWorkflowFiltersInput_1 = {
      pageSize: PAGE_SIZE,
      pageNumber: 1,
      adminUuids: [ptPooledDashboardAdminId || adminProfile.uuid],
      useCoverage
    };
    const workflowFiltersInput = {
      ...dashboardWorkflowFiltersInput_1,
      ...ptFilterStates[filterLabel_1],
      ...(enableWorkflowStateFilteringTreatment ? {
        excludeAllCompletedStates: true,
        excludeAllPassiveStates: true
      } : {
        completedStates
      }),
      dueTaskEndDate,
      order: sortWorkflows.order,
      orderBy: sortWorkflows.property
    };
    if (selectedDashboardFilter.filterTabName === NO_RESPONSE_PREDICTED_TAB_NAME) {
      workflowFiltersInput.priority = WorkflowPriority.Low;
    }
    getWorkflowsLazyQuery({
      variables: {
        workflowFiltersInput,
        includeExpirations: true
      },
      pollInterval: OVERDUE_CHECK_TIME,
      errorPolicy: 'all',
      fetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: false
    });
    setSseMismatch(false);
    setSseBulkMismatch(false);
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [selectedDashboardFilter.filterPageNumber, selectedDashboardFilter.filterTabName, ptPooledDashboardAdminId, adminProfile.uuid, completedStates, useCoverage, getWorkflowsLazyQuery, ptFilterStates, dueTaskEndDate, sortWorkflows.order, sortWorkflows.property, enableWorkflowStateFilteringTreatment]);
  return {
    workflows,
    error,
    loading
  };
};