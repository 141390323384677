import { ctpTokens, HHBox, HHButton, HHChip, HHDatePicker, HHDialogActions, HHDialogContent, HHDivider, HHList, HHListItem, HHSelect, HHSelectChangeEvent, HHSelectOption, HHStack, HHTextField, HHTextFieldProps, HHTypography, Typography } from '@hinge-health/react-component-library';
import { DeleteOutlineRounded, DoneRounded, InfoOutlined, UploadFileSharp } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { FRESHDESK_FIELDS, USER_FEEDBACK } from '../../../constants/strings/user-feedback';
import { useGenericMixpanelTrack } from '../../../hooks/use-generic-mixpanel';
import { MIXPANEL_EVENTS } from '../../../lib/mixpanel/mixpanel-event-names';
import { currentUserAtom } from '../../../modules/member-360/state/atoms';
import { FeedbackMessage, FeedbackType } from '../../../utils/freshdesk-dialog-utils';
import { FreshdeskTicketCustomFields, FreshdeskTicketDetails, TicketPriority } from './freshdesk-dialog';
import { layoutStyles } from './freshdesk-dialog-styles';
export const programSelectInputId = 'program-select';
export const indicationSelectInputId = 'indication-select';
export const formatTicketDate = (date: Date | null): string => date && dayjs(date).isValid() ? dayjs(date).startOf('day').format('YYYY-MM-DD') : '';
export const handleDateChange = (date: Date | null, setDateValue: (value: string) => void, setDateError: (value: boolean) => void, updateCustomFields: (callback: (prevData: FreshdeskTicketCustomFields) => FreshdeskTicketCustomFields) => void): void => {
  if (!date) {
    setDateValue('');
    setDateError(false);
    return;
  }
  const validDate = formatTicketDate(date);
  setDateValue(validDate);
  setDateError(!validDate);
  updateCustomFields((prevData: FreshdeskTicketCustomFields) => ({
    ...prevData,
    cf_date_of_issue: validDate
  }));
};
const createUserIdsArrayFromCustomFields = (cfUserIds?: string[] | string): string[] => {
  if (cfUserIds) {
    if (Array.isArray(cfUserIds)) {
      return cfUserIds;
    } else {
      return [cfUserIds];
    }
  }
  return [];
};
export const FreshdeskSubmissionForm = ({
  ...props
}): JSX.Element => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [typeChip, setTypeChip] = useState<string>(USER_FEEDBACK.BUG_STRING);
  const [role, setRole] = useState<string>(props.customFields.cf_internal_requester_type);
  const [request, setRequest] = useState<string>(props.customFields.cf_request_type_new);
  const [roleError, setRoleError] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<boolean>(false);
  const [titleHelperText, setTitleHelperText] = useState<string>('');
  const [descriptionError, setDescriptionError] = useState<boolean>(false);
  const [dateValue, setDateValue] = useState<string>(props.customFields.cf_date_of_issue);
  const [dateError, setDateError] = useState<boolean>(false);
  const [priorityValue, setPriorityValue] = useState<string>('');
  const [memberSelectionOption, setMemberSelectionOption] = useState<string>(USER_FEEDBACK.DIALOG_TEXTS.MEMBER_IMPACTED.ALL.toUpperCase());
  const [selectedProgram, setSelectedProgram] = useState<string>(props?.customFields?.cf_program || '');
  const [selectedIndication, setSelectedIndication] = useState<string>(props?.customFields?.cf_pathway || '');
  const currentUser = useAtomValue(currentUserAtom);
  const userIdsFromCustomFields = createUserIdsArrayFromCustomFields(props?.customFields?.cf_user_id);
  const [userIds, setUserIds] = useState(userIdsFromCustomFields ? userIdsFromCustomFields.join(', ') : '');
  const [userId, setUserId] = useState(currentUser?.id || (userIdsFromCustomFields?.length === 1 ? userIdsFromCustomFields[0] : ''));
  const genericMixpanelEvents = useGenericMixpanelTrack();
  const validateForm = (): boolean => {
    const subjectLength = props.freshdeskTicketDetails.subject.trim().length;
    const isTitleValid = subjectLength > 0 && subjectLength < USER_FEEDBACK.MAX_CHARACTER_LIMIT;
    const isDescriptionValid = !!props.freshdeskTicketDetails.description;
    const isRoleValid = !!role;
    const isRequestValid = !!request;
    setRoleError(!isRoleValid);
    setRequestError(!isRequestValid);
    setDescriptionError(!isDescriptionValid);
    if (!isTitleValid) {
      if (props.freshdeskTicketDetails.subject.trim().length === 0) {
        setTitleHelperText(USER_FEEDBACK.ERROR_TEXT_VALUE);
      } else if (props.freshdeskTicketDetails.subject.trim().length > 250) {
        setTitleHelperText(USER_FEEDBACK.CHARACTER_LIMIT_ERROR);
      }
    }
    return isTitleValid && isDescriptionValid && isRoleValid && isRequestValid && !dateError;
  };
  const handleSubmit = (): void => {
    genericMixpanelEvents(MIXPANEL_EVENTS.FRESHDESK_FORM_SUBMIT_BUTTON_CLICKED);
    if (validateForm()) {
      props.handleSubmit();
    }
  };
  const handleTitleChange = (value: string): void => {
    if (value.length > 250) {
      setTitleHelperText(USER_FEEDBACK.CHARACTER_LIMIT_ERROR);
    } else if (value.length === 0) {
      setTitleHelperText(USER_FEEDBACK.ERROR_TEXT_VALUE);
    } else {
      setTitleHelperText('');
    }
    props.updateTicketData((prevData: FreshdeskTicketDetails) => ({
      ...prevData,
      subject: value
    }));
  };
  // Function to trigger file input click
  const handleButtonClick = (): void => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Programmatically click the file input
    }
  };
  const handleToggleBugFeatureChip = (type: string): void => {
    setTypeChip(type);
    props.handleChipChange(type);
  };
  const memberImpactedOptions: HHSelectOption[] = Object.entries(USER_FEEDBACK.DIALOG_TEXTS.MEMBER_IMPACTED).map(([key, value_0]) => ({
    displayName: value_0,
    value: key
  }));
  const handlePriorityChange = (e: HHSelectChangeEvent): void => {
    const value_1 = (e.target.value as string);
    setPriorityValue(value_1);
    props.updateTicketData((prevData_0: FreshdeskTicketDetails) => ({
      ...prevData_0,
      priority: value_1 === FRESHDESK_FIELDS.CUSTOM_DROPDOWN_OPTION.PRIORITY_OPTIONAL[1] ? TicketPriority.High : TicketPriority.Medium
    }));
  };
  const handleKnowledgeBaseUrlClick = (e_0: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    genericMixpanelEvents(MIXPANEL_EVENTS.FRESHDESK_FORM_KNOWLEGE_BASE_LINK_CLICKED);
    e_0.preventDefault();
    window.open(USER_FEEDBACK.KNOWLEDGE_BASE_URL, '_blank', 'noopener noreferrer');
  };
  const singleUserIdChange = (e_1: React.ChangeEvent<HTMLInputElement>): void => {
    const value_2 = e_1.target.value;
    const regex = /^\d*$/;
    if (regex.test(value_2)) {
      setUserId(value_2);
      props.updateCustomFields((prevData_1: FeedbackMessage) => ({
        ...prevData_1,
        cf_user_id: value_2 ?? currentUser.id
      }));
    }
  };
  const handleMultipleUserIdsChange = (e_2: React.ChangeEvent<HTMLInputElement>): void => {
    const input = e_2.target.value.trim();
    const regex_0 = /^[0-9, ]*$/;
    if (regex_0.test(input)) {
      setUserIds(e_2.target.value);
      const userIds_0 = input.split(',').map(id => id.trim()).filter(Boolean);
      props.updateCustomFields((prevData_2: FeedbackMessage) => ({
        ...prevData_2,
        cf_user_id: userIds_0
      }));
    }
  };
  useEffect(() => {
    if (!currentUser) {
      return;
    }
    const pathways = currentUser?.pathways;
    if (pathways && pathways.length) {
      const CurrentPathway = pathways.find(pathway => pathway.isActive === true) || pathways[0];
      // setting pathway and program
      const pathway_0 = CurrentPathway.indication;
      const program = CurrentPathway.type.charAt(0).toUpperCase() + CurrentPathway.type.slice(1).toLocaleLowerCase();
      setSelectedProgram(program || '');
      // map proper pathway
      const pathwayFromPredefinedFields = FRESHDESK_FIELDS.CUSTOM_DROPDOWN_OPTION.CF_PATHWAY.find(pathwayOption => pathwayOption.toLowerCase().includes(pathway_0.toLowerCase()));
      setSelectedIndication(pathwayFromPredefinedFields || '');
      props.updateCustomFields((prevData_3: FeedbackMessage) => ({
        ...prevData_3,
        cf_pathway: pathwayFromPredefinedFields,
        cf_program: program,
        cf_user_id: currentUser.id
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);
  return <HHDialogContent sx={{
    padding: '0px 24px !important'
  }}>
      <HHTypography hhVariant="h4" layoutStyles={{
      padding: '15px 9px 0px 2px'
    }}>
        {USER_FEEDBACK.DIALOG_TEXTS.ABOUT_YOU}
      </HHTypography>
      <HHStack direction={'row'} spacing={2} sx={{
      padding: '12px 1px 12px 0px',
      width: '100%'
    }}>
        <HHSelect initialSelectValue={role} label={USER_FEEDBACK.DIALOG_TEXTS.ROLE} onChange={(e_3: HHSelectChangeEvent): void => {
        const role_0 = (e_3.target.value as string);
        setRole(role_0);
        setRoleError(false);
        props.updateCustomFields((prevData_4: FreshdeskTicketCustomFields) => ({
          ...prevData_4,
          cf_internal_requester_type: role_0
        }));
      }} selectOptions={FRESHDESK_FIELDS.CUSTOM_DROPDOWN_OPTION.CF_INTERNAL_REQUESTER_TYPE.map(option => ({
        value: option,
        label: option,
        displayName: option
      }))} size={'small'} width={'50%'} error={roleError} helperText={roleError ? USER_FEEDBACK.ERROR_TEXT_VALUE : ''} />
        <HHSelect initialSelectValue={request} label={USER_FEEDBACK.DIALOG_TEXTS.SUPPORT} onChange={(e_4: HHSelectChangeEvent): void => {
        const request_0 = (e_4.target.value as string);
        setRequest(request_0);
        setRequestError(false);
        props.updateCustomFields((prevData_5: FreshdeskTicketCustomFields) => ({
          ...prevData_5,
          cf_request_type_new: request_0
        }));
      }} selectOptions={FRESHDESK_FIELDS.CUSTOM_DROPDOWN_OPTION.CF_REQUEST_TYPE_NEW.map(option_0 => ({
        value: option_0,
        label: option_0,
        displayName: option_0
      }))} size={'small'} width={'50%'} error={requestError} helperText={requestError ? USER_FEEDBACK.ERROR_TEXT_VALUE : ''} />
      </HHStack>
      <HHTypography hhVariant="h4" layoutStyles={{
      paddingBottom: '13px',
      paddingTop: '8px'
    }}>
        {USER_FEEDBACK.DIALOG_TEXTS.MEMBER_IMPACTED_TITLE}
      </HHTypography>
      <HHBox sx={{
      paddingBottom: '15px'
    }}>
        <HHSelect onChange={(e_5: HHSelectChangeEvent): void => {
        setMemberSelectionOption(String(e_5.target.value));
      }} id="member-impacted-select" initialSelectValue={memberSelectionOption} selectOptions={memberImpactedOptions} size="small" sx={{
        width: '592px',
        marginBottom: '7px'
      }} />
        {memberSelectionOption === USER_FEEDBACK.DIALOG_TEXTS.MEMBER_IMPACTED.SINGLE.toUpperCase() && <HHStack direction="row" spacing={2} sx={{
        width: '100%',
        justifyContent: 'space-between',
        marginTop: 2,
        marginBottom: 1
      }}>
            <HHTextField label={USER_FEEDBACK.USER_ID} onChange={singleUserIdChange} hhVariant="outlined" variant="standard" value={userId} />

            <HHSelect label={USER_FEEDBACK.PROGRAM} id={programSelectInputId} initialSelectValue={selectedProgram} onChange={(e_6: HHSelectChangeEvent): void => {
          const value_3 = e_6.target.value;
          setSelectedProgram(value_3.toString());
          props.updateCustomFields((prevData_6: FeedbackMessage) => ({
            ...prevData_6,
            cf_program: value_3
          }));
        }} selectOptions={FRESHDESK_FIELDS.CUSTOM_DROPDOWN_OPTION.CF_PROGRAM.map(option_1 => ({
          value: option_1,
          label: option_1,
          displayName: option_1
        }))} width={'33%'} />

            <HHSelect label={USER_FEEDBACK.INDICATION_LABEL} initialSelectValue={selectedIndication} id={indicationSelectInputId} onChange={(e_7: HHSelectChangeEvent): void => {
          const value_4 = e_7.target.value;
          setSelectedIndication(value_4.toString());
          props.updateCustomFields((prevData_7: FeedbackMessage) => ({
            ...prevData_7,
            cf_pathway: value_4
          }));
        }} selectOptions={FRESHDESK_FIELDS.CUSTOM_DROPDOWN_OPTION.CF_PATHWAY.map(option_2 => ({
          value: option_2,
          label: option_2,
          displayName: option_2
        }))} width={'33%'} />
          </HHStack>}

        {memberSelectionOption === USER_FEEDBACK.DIALOG_TEXTS.MEMBER_IMPACTED.MULTIPLE.toUpperCase() && <HHStack direction="column" spacing={2} mt={2}>
            <HHTypography hhVariant="h5">
              User IDs (comma separated)
            </HHTypography>
            <HHTextField label={USER_FEEDBACK.MULTIPLE_USER_ID} hhVariant="outlined" variant="standard" onChange={handleMultipleUserIdsChange} placeholder={USER_FEEDBACK.DIALOG_TEXTS.MEMBER_ID_PLACEHOLDER} value={userIds} />
          </HHStack>}
      </HHBox>
      <HHTypography hhVariant="h4" layoutStyles={{
      paddingBottom: '15px'
    }}>
        {USER_FEEDBACK.DIALOG_TEXTS.TITLE_BUG_REQUEST_QUES}{' '}
      </HHTypography>
      <HHChip clickable color={typeChip === USER_FEEDBACK.BUG_STRING ? 'secondary' : 'default'} hhVariant={'filled'} label={USER_FEEDBACK.BUG_STRING.charAt(0).toUpperCase() + USER_FEEDBACK.BUG_STRING.slice(1).toLocaleLowerCase()} onClick={(): void => {
      handleToggleBugFeatureChip(USER_FEEDBACK.BUG_STRING);
    }} />
      <HHChip clickable hhVariant={'filled'} color={typeChip === USER_FEEDBACK.REQUEST_STRING ? 'secondary' : 'default'} layoutStyles={{
      marginLeft: '10px'
    }} label={USER_FEEDBACK.REQUEST_STRING.charAt(0).toUpperCase() + USER_FEEDBACK.REQUEST_STRING.slice(1).toLocaleLowerCase()} onClick={(): void => {
      handleToggleBugFeatureChip(USER_FEEDBACK.REQUEST_STRING);
    }} />
      <HHBox sx={{
      padding: '10px 2px 16px 2px',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px'
    }}>
        <HHTextField hhVariant="outlined" label={USER_FEEDBACK.DIALOG_TEXTS[(typeChip as FeedbackType)].TITLE_PLACEHOLDER} InputProps={{
        minRows: 1,
        className: 'title-input'
      }} placeholder={USER_FEEDBACK.DIALOG_TEXTS[(typeChip as FeedbackType)].TITLE_PLACEHOLDER} size={'small'} defaultValue={props.freshdeskTicketDetails.subject} fullWidth sx={{
        width: '100%'
      }} onChange={(e_8): void => {
        handleTitleChange(e_8.target.value.trim());
      }} error={Boolean(titleHelperText)} helperText={titleHelperText} />

        <HHTextField hhVariant="outlined" label={USER_FEEDBACK.DIALOG_TEXTS[(typeChip as FeedbackType)].DESCRIPTION_PLACEHOLDER} multiline fullWidth rows={4} placeholder={USER_FEEDBACK.DIALOG_TEXTS[(typeChip as FeedbackType)].DESCRIPTION_PLACEHOLDER} size={'medium'} defaultValue={props.freshdeskTicketDetails.description} sx={{
        width: '100%'
      }} onChange={(e_9): void => {
        setDescriptionError(false);
        props.updateTicketData((prevData_8: FreshdeskTicketDetails) => ({
          ...prevData_8,
          description: e_9.target.value.trim()
        }));
      }} error={descriptionError} helperText={descriptionError ? USER_FEEDBACK.ERROR_TEXT_VALUE : ''} />
      </HHBox>
      <HHBox sx={{
      padding: '0px 2px 15px 2px',
      display: 'flex',
      gap: '16px',
      flexWrap: 'wrap'
    }}>
        <HHStack direction={'row'} spacing={2} sx={{
        width: '100%',
        justifyContent: 'space-between'
      }}>
          <HHBox width={'50%'}>
            <HHDatePicker onChange={(date: Date | null): void => handleDateChange(date, setDateValue, setDateError, props.updateCustomFields)} value={dateValue ? dayjs(dateValue).toDate() : null} label={USER_FEEDBACK.DIALOG_TEXTS.BUG.DATE_OF_ISSUE} disableFuture renderInput={(params): JSX.Element => <HHTextField {...(params as HHTextFieldProps)} hhVariant="outlined" size="small" fullWidth error={dateError} helperText={dateError ? USER_FEEDBACK.DATE_ERROR : ''} />} PopperProps={{
            style: {
              width: '320px'
            },
            placement: 'bottom-start'
          }} />
          </HHBox>
          <HHStack width={'50%'}>
            <HHSelect initialSelectValue={priorityValue} label={USER_FEEDBACK.PRIORITY_TITLE} id={USER_FEEDBACK.PRIORITY_ID} onChange={handlePriorityChange} selectOptions={FRESHDESK_FIELDS.CUSTOM_DROPDOWN_OPTION.PRIORITY_OPTIONAL.map(option_3 => ({
            value: option_3,
            label: option_3,
            displayName: option_3
          }))} MenuProps={{
            PaperProps: {
              style: {
                width: '270px'
              }
            },
            sx: {
              '& .MuiMenuItem-root': {
                whiteSpace: 'normal',
                lineHeight: '1.2'
              }
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            }
          }} />
          </HHStack>
        </HHStack>
      </HHBox>
      <HHTypography hhVariant="h4">
        {USER_FEEDBACK.DIALOG_TEXTS.ATTACHMENT_TEXT}
      </HHTypography>
      <input data-testid={USER_FEEDBACK.TEST_IDS.FILE_INPUT} type={'file'} accept={'image/*, video/*, .har, .doc, .docx, .pdf'} multiple ref={fileInputRef} style={{
      display: 'none'
    }} onClick={(e_10): void => e_10.stopPropagation()} onChange={props.handleFileUpload} />
      <HHButton hhVariant={'outlined'} layoutStyles={{
      marginTop: '8px',
      marginBottom: '5px',
      lineHeight: '26px'
    }} onClick={handleButtonClick}>
        <UploadFileSharp sx={{
        marginRight: '8px',
        fontSize: '13px'
      }} />{' '}
        {USER_FEEDBACK.UPLOAD_FILES_TEXT}
      </HHButton>
      {props.fileUploadSizeError && <HHBox sx={layoutStyles.fileUploadErrorBox}>
          {props.fileUploadSizeError}
        </HHBox>}
      <br />
      <Box sx={props.sxStyles.phiDisclaimerBox}>
        <Stack direction={'row'} spacing={2}>
          <InfoOutlined sx={{
          color: 'rgba(103, 111, 109, 1)'
        }} />{' '}
          <Typography sx={props.sxStyles.phiDisclaimerTypography} align={'center'}>
            {USER_FEEDBACK.DISCLAIMER_TEXT}
          </Typography>
        </Stack>
      </Box>

      {/* Uploaded files will show here */}
      {props.feedbackFiles && props.feedbackFiles.length > 0 && <HHList>
          {Array.from((props.feedbackFiles as File[])).map((file: File, index: number) => <HHListItem key={file.name} sx={props.sxStyles.hhListItem}>
                <Stack direction={'row'} spacing={'12px'} alignItems="center" sx={props.sxStyles.stack}>
                  <DoneRounded sx={{
            color: 'rgba(0, 0, 0, 0.54)'
          }} />
                  <Typography variant="body1" sx={{
            flex: 1
          }}>
                    {file.name}
                  </Typography>
                  <DeleteOutlineRounded data-testid={USER_FEEDBACK.TEST_IDS.DELETE_BUTTON + index} sx={{
            marginLeft: '100px',
            color: 'rgba(0, 0, 0, 0.54)',
            cursor: 'pointer'
          }} onClick={(): void => props.handleDeleteItem(index)} />
                </Stack>
              </HHListItem>)}
        </HHList>}
      <HHDialogActions sx={{
      ...props.sxStyles.hhDialogActions,
      justifyContent: 'space-between',
      backgroundColor: 'white',
      width: '100%',
      zIndex: 50
    }}>
        <HHStack width={'100%'} rowGap={4}>
          <HHDivider sx={props.sxStyles.divider} />
          <HHStack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
            <HHTypography sx={{
            fontSize: '14px'
          }} hhVariant="variant-bypass" color={ctpTokens.palette.neutral[10]}>
              Review
              <button style={props.sxStyles.baseUrl} onClick={handleKnowledgeBaseUrlClick}>
                Knowledge Base URL
              </button>
              before submitting
            </HHTypography>
            <HHButton sx={props.sxStyles.submitButton} hhVariant="variant-bypass" onClick={handleSubmit} fullWidth={false} disabled={props.loader}>
              {USER_FEEDBACK.SUBMIT_FORM_BUTTON_TEXT}
            </HHButton>
          </HHStack>
        </HHStack>
      </HHDialogActions>
    </HHDialogContent>;
};