import { AccessToken } from '@okta/okta-auth-js';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { v4 as uuidV4 } from 'uuid';
import { FreshdeskTicketCustomFields, FreshdeskTicketDetails } from '../components/navigation-components/feedback-dialog/freshdesk-dialog';
import { ABORT_UPLOAD_TIMEOUT, USER_FEEDBACK } from '../constants/strings/user-feedback';
export interface FeedbackParams {
  ticketDetails: FreshdeskTicketDetails;
  customTicketDetails: FreshdeskTicketCustomFields;
  chipStatus: string;
}
export interface FeedbackMessage {
  feedbackType: string;
  title: string;
  description: string;
  memberUuids?: string;
}
export interface AuthClientParams {
  accessToken: AccessToken;
  getOrRenewAccessToken: () => Promise<string | null>;
}
export type FeedbackType = 'BUG' | 'ENHANCEMENT';
interface ValidationResult {
  status: boolean;
  message: string;
}
type CustomFieldKey = keyof FreshdeskTicketCustomFields;
export interface PresignedUrlData {
  fileName: string;
  presignedUrl: string;
}
interface PresignedUrlResponse {
  data: PresignedUrlData[];
}
const processNumbers = (input: string): {
  firstNumber: number;
  restOfNumbers: string;
} => {
  // split the user id with space, comma, or semicolon
  const numbers = input.split(/[\s,;]+/).map(Number).filter(n => !isNaN(n));
  // Get the first number
  const firstNumber = numbers[0];
  // Get the rest of the numbers
  const restOfNumbers = numbers.slice(1);
  // Format the rest of the numbers as a comma-separated string
  const formattedRest = restOfNumbers.join(',');
  return {
    firstNumber,
    restOfNumbers: formattedRest
  };
};
export const validateTicketAttachment = (attachments: FileList | null, uploadAttachmentsUsingS3: boolean): ValidationResult => {
  const getTotalFileSize = (attachments: FileList): number => {
    let totalSize = 0;
    for (const file of attachments) {
      totalSize += file.size;
    }
    return totalSize;
  };
  const maxFileSizeLimit = (uploadAttachmentsUsingS3 ? USER_FEEDBACK.MAX_FILE_SIZE_IN_MB : USER_FEEDBACK.MAX_FILE_SIZE_IN_MB_OLD) * 1024 * 1024;
  if (attachments && attachments.length && getTotalFileSize(attachments) > maxFileSizeLimit) {
    const errorMessage = uploadAttachmentsUsingS3 ? USER_FEEDBACK.UPLOAD_ERROR_MESSAGE : USER_FEEDBACK.UPLOAD_ERROR_MESSAGE_OLD;
    return {
      status: false,
      message: errorMessage
    };
  }
  return {
    status: true,
    message: ''
  };
};
export function getFreshdeskRequestConfig(token: string): AxiosRequestConfig {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
}
export async function maybeRenewToken({
  accessToken,
  getOrRenewAccessToken
}: AuthClientParams): Promise<string> {
  const expiresAt = dayjs.unix(accessToken.expiresAt); // unix takes time
  if (expiresAt.diff(dayjs(), 'seconds') > 10) {
    return accessToken?.accessToken;
  }
  const newToken = await getOrRenewAccessToken();
  if (!newToken) {
    throw Error('Cannot renew access token (token is empty)');
  }
  return newToken;
}
export const freshdeskCreateTicket = async (feedbackParams: FeedbackParams, authClientParams: AuthClientParams): Promise<unknown> => {
  const {
    ticketDetails,
    customTicketDetails,
    chipStatus
  } = feedbackParams;
  const {
    accessToken,
    getOrRenewAccessToken
  } = authClientParams;
  const userIds = customTicketDetails.cf_user_id;
  ticketDetails.description = chipStatus + ': ' + '\n' + ticketDetails.description;
  if (userIds) {
    const {
      firstNumber,
      restOfNumbers
    } = processNumbers(String(userIds));
    customTicketDetails.cf_user_id = firstNumber;
    if (restOfNumbers) {
      ticketDetails.description = ticketDetails.description + '\n' + 'User IDs: ' + restOfNumbers;
    }
  }
  const custom_fields: FreshdeskTicketCustomFields = {};
  if (customTicketDetails.cf_user_id) {
    custom_fields.cf_user_id = customTicketDetails.cf_user_id;
  }
  Object.entries(customTicketDetails).forEach(([key, value]) => {
    if (value && typeof value === 'string') {
      custom_fields[(key as CustomFieldKey)] = value;
    }
  });
  try {
    const token = await maybeRenewToken({
      accessToken,
      getOrRenewAccessToken
    });
    const response = await axios.post(process.env.REACT_APP_BFF_URL + USER_FEEDBACK.CTT_BFF_CREATE_TICKET_PATH, {
      ...ticketDetails,
      custom_fields
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    return {
      message: (error as Error).message
    };
  }
};
const generateUniqueFileMap = (attachments: FileList): Map<string, File> => {
  const fileMap = new Map<string, File>();
  for (const file of Array.from(attachments)) {
    const ext = file.name.includes('.') ? file.name.split('.').pop() : '';
    const uniqueName = ext ? `${uuidV4()}.${ext}` : uuidV4();
    fileMap.set(uniqueName, file);
  }
  return fileMap;
};
export const getPresignedUrlsForUpload = async (ticketId: string, fileNames: string[], token: string): Promise<PresignedUrlResponse> => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BFF_URL}${USER_FEEDBACK.CTT_BFF_GET_PRE_SIGNED_URLS_FOR_UPLOAD_PATH}`, {
      ticketId,
      fileNames
    }, getFreshdeskRequestConfig(token));
    return response.data;
  } catch (error) {
    throw new Error('[Freshdesk] Error fetching presigned URLs', (error as Error));
  }
};
export const uploadFilesToS3 = async (fileMap: Map<string, File>, presignedData: Array<PresignedUrlData>): Promise<void> => {
  const uploadPromises = presignedData.map(({
    fileName,
    presignedUrl
  }) => {
    // Throw an error if file is not found as per presigned URL response
    if (!fileMap.has(fileName)) {
      throw new Error(`[Freshdesk] No matching file found for filename: ${fileName}`);
    }
    const file = fileMap.get(fileName);
    return axios.put(presignedUrl, file, {
      // Abort the upload request if it takes more than 30 seconds
      timeout: ABORT_UPLOAD_TIMEOUT,
      timeoutErrorMessage: `[Freshdesk] Timeout uploading file to S3: ${fileName}`
    });
  });
  try {
    await Promise.all(uploadPromises);
  } catch (error) {
    throw new Error('[Freshdesk] Error uploading files to S3', (error as Error));
  }
};
export const attachFilesToFreshdesk = async (ticketId: string, fileNames: string[], token: string): Promise<void> => {
  try {
    await axios.post(`${process.env.REACT_APP_BFF_URL}${USER_FEEDBACK.CTT_BFF_UPLOAD_ATTACHMENT_PATH}`, {
      ticketId,
      fileNames
    }, getFreshdeskRequestConfig(token));
  } catch (error) {
    throw new Error('[Freshdesk] Error attaching files to freshdesk from S3', (error as Error));
  }
};
export const uploadTicketAttachments = async (ticketId: string, attachments: FileList, authClientParams: AuthClientParams): Promise<void> => {
  const token = await maybeRenewToken(authClientParams);
  const fileMap = generateUniqueFileMap(attachments);
  const fileNames = Array.from(fileMap.keys());
  const {
    data
  } = await getPresignedUrlsForUpload(ticketId, fileNames, token);
  await uploadFilesToS3(fileMap, data);
  await attachFilesToFreshdesk(ticketId, fileNames, token);
};

/**
 * @deprecated  Use `uploadTicketAttachments` instead once the new API is fully integrated
 * @todo Remove this function once the new upload attachments flow is stable
 */
export const uploadTicketAttachmentsOld = async (ticketId: string, attachments: FileList | null, authClientParams: AuthClientParams): Promise<AxiosResponse | null> => {
  const {
    accessToken,
    getOrRenewAccessToken
  } = authClientParams;
  if (!attachments || !attachments.length) return null;
  const formData = new FormData();
  formData.append('ticketId', ticketId);
  if (attachments && attachments.length) {
    for (const file of attachments) {
      formData.append('files', file);
    }
  }
  try {
    const token = await maybeRenewToken({
      accessToken,
      getOrRenewAccessToken
    });
    const fileUploadResponse = await axios.post(process.env.REACT_APP_BFF_URL + USER_FEEDBACK.CTT_BFF_UPLOAD_ATTACHMENT_PATH_OLD, formData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return fileUploadResponse;
  } catch (error) {
    throw new Error('[Freshdesk] Error uploading attachments', (error as Error));
  }
};