import { commonCoachCompletedStates, commonCoachExcludedStates } from '../../../../custom-types';
import { commonOsCompletedStates, commonOsExcludedStates } from '../../../dashboard/types/os';
import { commonPtCompletedStates, commonPtExcludedStates } from '../../../dashboard/types/pt';
export const FETCH_ERROR = 'An error occurred while fetching workflows.';
export const NO_WORKFLOWS = 'No open workflows';
export const WORKFLOW_HISTORY_TITLE = 'Workflow history';
export const NO_OLDER_WORKFLOWS = 'No older workflows for this member';
export const VIEW_MORE = 'View more';
export const VIEW_LESS = 'View less';
export const WORKFLOW_COMPLETE_NETWORK_ERROR = 'Workflow not completed due to network error';
export const WORKFLOW_COMPLETE_ERROR = 'Something went wrong while completing the workflow.';
export const WORKFLOWS_PER_PAGE = 5;
export const allExcludedStates = [...commonCoachExcludedStates, ...commonPtExcludedStates, ...commonOsExcludedStates];
export const allCompletedStates = [...commonCoachCompletedStates, ...commonPtCompletedStates, ...commonOsCompletedStates];
export const NBA_NOT_GENERATED = 'Not Generated';
// eslint-disable-next-line quotes
export const MEMBERS_CONTEXT = "Member's Context";
export const SUBMIT_FEEDBACK_ERROR = 'Unable to submit feedback. Please try again.';